<template>
  <a-modal
    :maskClosable="false"
    title="查看发票信息"
    style="top: 8px"
    :width="1200"
    v-model="visible"
    :footer="null"
  >
    <a-tabs default-active-key="1" size="small" type="card">
      <a-tab-pane key="1" tab="基础信息" force-render>
        <a-divider orientation="left">发票信息</a-divider>
        <a-descriptions :column="2" size="small">
          <a-descriptions-item label="开票主体">{{ info.openInvoiceInfo }}</a-descriptions-item>
          <a-descriptions-item label=""></a-descriptions-item>
          <a-descriptions-item label="发票类型">
            {{ info.invoiceType }}
          </a-descriptions-item>
          <a-descriptions-item label="抬头类型">{{ info.receivingType == 1 ? '个人' : '公司' }}</a-descriptions-item>
          <a-descriptions-item :label="info.receivingType == 1 ? '发票抬头' : '公司名称'">{{
            info.invoiceTitle
          }}</a-descriptions-item>
          <a-descriptions-item label="纳税人识别码">{{ info.taxpayerNumber }}</a-descriptions-item>
          <a-descriptions-item label="注册电话">{{ info.regPhone }}</a-descriptions-item>
          <a-descriptions-item label="注册地址">{{ info.regAddress }}</a-descriptions-item>
          <a-descriptions-item label="开户银行">{{ info.depositBank }}</a-descriptions-item>
          <a-descriptions-item label="银行账号">{{ info.bankAccount }}</a-descriptions-item>
          <a-descriptions-item label="税率">
            {{ info.taxRate }}
          </a-descriptions-item>
          <a-descriptions-item label="开票内容">
            {{ info.invoiceContent }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider orientation="left" v-if="info.remark">发票备注</a-divider>
        <a-descriptions>
          <a-descriptions-item v-if="info.remark">
            {{ info.remark }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider orientation="left">邮寄地址</a-divider>
        <a-descriptions :column="1" size="small">
          <a-descriptions-item label="收货人">
            {{info.consignee}}
          </a-descriptions-item>
          <a-descriptions-item label="收货人手机号码">
            {{info.consigneePhone}}
          </a-descriptions-item>
          <a-descriptions-item label="收货地址">
            {{info.province}}{{info.city}}{{info.area}}{{info.address}}
          </a-descriptions-item>
        </a-descriptions>
      </a-tab-pane>
      <a-tab-pane key="2" tab="订单详情" force-render>
        <div v-if="info.details">
          <a-table :data-source="info.details" bordered rowKey="shipId" :pagination="false">
            <a-table-column title="发货单号" data-index="shipCode" :width="100" :ellipsis="true" align="left" />
            <a-table-column title="实际金额" data-index="payPrice" :width="100" :ellipsis="true" align="left" />
            <a-table-column title="可开票金额" data-index="invoicePrice" :width="100" :ellipsis="true" align="left" />
            <a-table-column title="商品总数" data-index="goodsCount" :width="100" :ellipsis="true" align="left" />
          </a-table>
        </div>
      </a-tab-pane>
    </a-tabs>
    <!-- 选择抬头 -->
    <a-modal
      title="更换抬头"
      style="top: 18px"
      :width="1000"
      :visible="chooseTitleVisible"
      :confirm-loading="chooseTitleConfirmLoading"
      @ok="chooseTitleHandleOk"
      @cancel="chooseTitleHandleCancel"
      :maskClosable="false"
    >
      <p>
        <a-table
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: chooseTitleOnChange,
            type: 'radio',
            getCheckboxProps: (record) => ({
              props: {
                defaultChecked: record.id == info.dealerInvoiceId,
              },
            }),
          }"
          :columns="columns"
          :data-source="chooseTitleData"
          rowKey="id"
        />
      </p>
    </a-modal>
  </a-modal>
</template>

<script>
import { idcard } from '@/utils/rules'
const qs = require('qs')
const columns = [
  {
    title: '发票抬头',
    dataIndex: 'invoiceTitle',
  },
  {
    title: '抬头类型',
    dataIndex: 'receivingType',
    key: 'receivingType',
    customRender: function (text) {
      if (text == 1) {
        return '个人'
      } else {
        return '公司'
      }
    },
  },
  {
    title: '纳税人识别号',
    dataIndex: 'taxpayerNumber',
  },
  {
    title: '开户银行名称',
    dataIndex: 'depositBank',
  },
  {
    title: '基本开户账号',
    dataIndex: 'bankAccount',
  },
  {
    title: '注册场所地址',
    dataIndex: 'regAddress',
  },
  {
    title: '注册电话',
    dataIndex: 'regPhone',
  },
]
export default {
  data() {
    return {
      rowData: {},
      info: {},
      shipList: [],
      shipInfo: {},
      visible: false,
      logList: [],
      queryParam: {},
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      handle: '',
      InvoiceType: [],
      InvoiceTaxRate: [],
      chooseTitleVisible: false,
      chooseTitleConfirmLoading: false,
      columns,
      chooseTitleData: [],
      selectedRowKeys: [],
    }
  },
  methods: {
    //选择标题
    chooseTitleOnChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    //选择抬头取消事件
    chooseTitleHandleCancel() {
      this.chooseTitleVisible = false
    },
    //选择抬头确定事件
    chooseTitleHandleOk() {
      if (this.selectedRowKeys.length > 0) {
        const chooseId = this.selectedRowKeys[0]
        for (let i = 0; i < this.chooseTitleData.length; i++) {
          if (this.chooseTitleData[i].id == chooseId) {
            this.$set(this.info, 'invoiceTitle', this.chooseTitleData[i].invoiceTitle)
            this.$set(this.info, 'receivingType', this.chooseTitleData[i].receivingType)
            this.$set(this.info, 'taxpayerNumber', this.chooseTitleData[i].taxpayerNumber)
            this.$set(this.info, 'depositBank', this.chooseTitleData[i].depositBank)
            this.$set(this.info, 'bankAccount', this.chooseTitleData[i].bankAccount)
            this.$set(this.info, 'regAddress', this.chooseTitleData[i].regAddress)
            this.$set(this.info, 'regPhone', this.chooseTitleData[i].regPhone)
            this.chooseTitleVisible = false
            return
          }
        }
      } else {
        this.$message.warning('请选择一条数据')
      }
    },
    //选择发票抬头
    chooseInvoiceTitle() {
      this.chooseTitleVisible = true
    },
    InvoiceTypeHandleChange(value) {
      console.log(`InvoiceTypeHandleChange ${value}`)
      this.info.invoiceTypeId = value
    },
    invoiceContentTypeHandleChange(value) {
      console.log(`invoiceContentTypeHandleChange ${value}`)
      this.info.invoiceContentType = value
    },
    taxRateIdHandleChange(value) {
      console.log(`taxRateIdHandleChange ${value}`)
      this.info.taxRateId = value
    },
    toCancel() {
      this.visible = false
    },
    onSubmit() {
      this.axios.post('/api/order/invoice/orderShipInfo/add', this.info).then((res) => {
        if (res.code == 200) {
          this.$notification.success({ message: res.message })
          this.visible = false
          this.$emit('change')
        }
      })
    },
    
    showInfo(id) {
      this.visible = true
      this.axios
        .get(`/api/order/invoice/orderShipInfo/selectById/${id}`)
        .then((res) => {
          if (res.code == 200) {
            this.info = res.body
          }
        })
        .catch((err) => {})
    },
  },
  created() {
    this.axios.get(`/api/base/system/dictionary/selectByCode/发票类型`).then((res) => {
      if (res.code == 200) {
        this.InvoiceType = res.body
      }
    })
    this.axios.get(`/api/base/system/dictionary/selectByCode/发票税率`).then((res) => {
      if (res.code == 200) {
        this.InvoiceTaxRate = res.body
      }
    })
    this.axios.get(`/api/dealer/dealer/dealerInfo/selectChooseInvoiceInfo`).then((res) => {
      if (res.code == 200) {
        this.chooseTitleData = res.body
      }
    })
  },
}
</script>

<style lang="less" scoped>
.shipments-bts {
  text-align: right;
  // margin-top: 20px;
}
</style>
